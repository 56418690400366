import React from 'react'


import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/components/home.css"

class Accordin extends React.Component {
    constructor(props) {
    super(props);
    this.sectionToggle = this.sectionToggle.bind(this);
    this.state = {
      toggle: false
    }
  }
  
    sectionToggle(e) {

        var x = document.querySelectorAll("div.section");
        let a = e;
        for(var i = 0; i < x.length; i++){

            if(i === a) {
                if(x[i].classList.contains('active')){
                    x[i].classList.remove('active');
                    this.setState({toggle: !this.state.toggle})
                
                }else{
                    x[i].classList.add('active');
                    this.setState({toggle: !this.state.toggle})
                    
                }
            }else{
                if(!x[i].classList.contains('active')){
                    x[i].classList.remove('active');
                    this.setState({toggle: !this.state.toggle})

                }      
            }

        }
    
    }
  render() {
    return (
        <div className="apps">
          <div>
            <div className="application-btn" onClick={e => this.sectionToggle(this.props.k)}>
              <div className="row">
                <div className="col-12" style={{textAlign:'start'}}>
                    <div className=" row h1">
                      {this.props.name}

                     <div className='toggle1' >
                       {
                         !this.state.toggle? "+" : "-"
                       }
                       
                       </div>
                    </div>
                     
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <span className="desc"  dangerouslySetInnerHTML={{__html: this.props.description}}></span>
            <br/>{
              this.props.cta && this.props.ctaLabel ? <button className="cta-btn" onClick={()=> window.open(this.props.cta,'_blank')}>
              {this.props.ctaLabel}
            </button> :
            ""
            }
            
          </div>
        </div>
    )
  }
}

export default Accordin