import React from "react"
import Layout from "../components/simpleLayout"
import SEO from "../components/seo"
import Accordin from '../components/accordin'

import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/components/home.css"
import siteConfig from '../../config'

const Applications = () => {

  var applicationData = [
    
    {
      name:"KTU CGPA Calculator",
      description: `<strong class="white">2015</strong>: This is where my journey began. 
                    <br/><br/>
                    PROBLEM: Being the first batch of Kerala Technological University, there was no easy method to find the CGPA of the students. 
                    <br/>
                    SOLUTION: I created a simple app using MIT App Inventor to check that.`
                                                          
    },
    {
      name:"Electricity Bill Calculator",
      description: `<strong class="white">2016</strong>: On seeing the first app, some seniors of our college asked if I could make an app that would give the an approximate estimate of the electricity bill after entering the details of the home appliances. This was also made using MIT App Inventor.`
                                                          
    },
    {
      name:"iCubePEC",
      description: `<strong class="white">2017</strong>: This app was made for Innovation and Entrepreneurship Development Cell to spread tech news and details about other events among the students of the college. This app was built in Android Studio. The main challenge for this project was implementing YouTube and Blogger APIs to provide data into the application.  This was a proud moment for me because this was the first app that I made that was published in Google Play Store. Unfortunately the app is now unpublished because it was not updated on time.`
                                                          
    },
    {
      name:"Asish George Official",
      description: `<strong class="white">2019</strong>: 

        My Portfolio - Version 1. This site was built right after I got out of college. This was a great learning experience for me. Here's why: 

        <br/> <br/> 

        1. Designed and Coded everything. No Themes used. <br/>
        2. Used NodeJs and Firebase to deliver the site.<br/>
        3. Learnt a lot about optimizing the site for SEO, site performance and design.<br/>
        4. The architecture that I used to server side render in Firebase was not good. I had to begin everything from scratch and hence this site. <br/>

      `,
      ctaLabel:"AG Official",
      cta:"https://asishgeorgeofficial.web.app/"
                                                          
    },
    {
      name:"CheckJSON",
      description: `<strong class="white">2019</strong>: A site was born out of a small requirement that arose in the workplace. We wanted a site to format JSON in browser itself. I wanted experiment on that and so this site was born.<br/> `,
      ctaLabel:"CheckJSON Repo",
      cta:"https://github.com/asishgeorge/checkJSON"                                                    
    },
    {
      name: "InternHunts",
      description:`
      <strong class="white">2020</strong>:
      A friend asked if there was a way to find an internship for him. In the hunt for that among all the sites that are there for internships, I thought it would be nice if we could gather data from all the  internship sites and show it in one place. That is how I made this. 

      <br/> 

      This was my first experience in web scraping both in Python and in NodeJs. Tried both and stuck to node. Website was built using Vue and Firebase for deployment.
       <br/> 
      `,
      ctaLabel:"InternHunts Repo",
      cta:"https://github.com/asishgeorge/internhunts"
    },

  ]
  return (<Layout>
    <SEO title="Applications by Asish George" keywords={siteConfig.keywords}/>
    {/* <h1>Hello</h1> */}

    <section>
      <div className="container width">
        <div className="row "> 
          <div className="col text-center">
            <h1 className="heading">Applications</h1> 
          </div>
        </div>

      {
        applicationData && applicationData.length !== 0 ? 

        applicationData.reverse().map((item, key) => {
             return <Accordin 
                name={item.name}
                k={key}
                key={key}
                description={item.description}
                ctaLabel={item.ctaLabel}
                cta={item.cta}

              /> 
        }): 
        ""
      }
       
        
      
        
      </div>
      
    </section>
  </Layout>)
}

export default Applications
